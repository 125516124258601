.footer {
    background-color: var(--alternative-background-color);
    color: var(--alternative-text-color);
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 10vw;
}
.footer__logo,
.footer__social-media-item {
    fill: var(--alternative-primary-color);
}
.footer__logo {
    height: 2.8em;
    width: 5em;
}
.footer__social-media {
    display: flex;
    justify-content: space-between;
    width: 30%;
}
.footer__social-media-item {
    height: 1.5em;
    width: 1.5em;
}

.footer__slogan {
    margin: 0;
    font-size: 1.1em;
    font-style: italic;
}

.footer__rights {
    color: var(--alternative-important-text-color);
    margin: 0;
}

@media (min-width: 630px) {
    .footer {
        padding-right: 10vw;
    }
    .footer__logo {
        height: 4em;
        width: 5.5em;
    }
    .footer__social-media-item {
        height: 2em;
        width: 2em;
    }

    .footer__social-media {
        width: 20%;
    }
    .footer__slogan {
        font-size: 1.5em;
    }
}
@media (min-width: 1200px) {
    .footer__slogan {
        order: -1;
        width: 57vw;
        text-align: center;
    }
    .footer__logo {
        order: -2;
        width: 9vw;
    }
    .footer__social-media {
        width: 9vw;
    }
    .footer {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 5vw;
        height: 15vh;
    }
    .footer__rights {
        width: 50vw;
        text-align: center;
    }
}
