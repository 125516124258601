.skill {
    padding-top: 6em;
}
.skills {
    width: 100%;
}

.skills__container {
    display: flex;
}
.skill__container {
    display: flex;
    justify-content: center;
    float: left;
    width: 100vw;
}

.skill__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.skill__image {
    max-width: 80%;
    height: 35vh;
}

.skill__title {
    margin-top: 0em;
    margin-bottom: 0.5em;
}
.skill__subtitle {
    color: var(--title-color);
    font-weight: 200;
    font-size: 1.5em;
}
.skills__optional {
    display: none;
}
@media (min-width: 630px) {
    .skill__subtitle {
        font-size: 1.8em;
    }
    .skills__optional {
        display: initial;
    }
}

@media (min-width: 1200px) {
    .skill__item {
        flex-direction: row;
        width: 90%;
        justify-content: space-around;
    }
    .skill__image {
        width: 40%;
        height: auto;
    }
    .skill__details {
        width: 40%;
    }
    .skill__description {
        width: 90%;
    }
    .skill__subtitle {
        font-size: 2em;
    }
    .skills {
        justify-content: center;
    }
    .skill__container {
        height: 70vh;
    }
}
