.home {
    max-height: 100vh;
}
.home__draw {
    width: 100vw;
}
.home__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__logo {
    width: 30vw;
    margin: 1em;
}

.home__description {
    color: var(--important-text-color);
    font-size: 1em;
}

.home__social-media {
    display: flex;
    width: 35vw;
    margin: 1em;
    justify-content: space-around;
    align-items: center;
}
.home__social-media-item {
    width: 30px;
}

.home__footer {
    font-size: 0.9em;
    margin-top: 2.3em;
    display: flex;
    flex-direction: column;
}

.home__arrow {
    padding-top: 0.8em;
    height: 1.5em;
    animation: down 2s;
    animation-iteration-count: infinite;
}

@keyframes down {
    0% {
        margin-top: 0em;
    }
    50% {
        margin-top: 1em;
    }
    100% {
        margin-top: 0em;
    }
}
@media (max-width: 320px) {
    .home__footer {
        margin-top: 0;
    }
}

@media (min-width: 630px) {
    .home__draw {
        width: 95vw;
    }
    .home__logo {
        width: 25vw;
    }

    .home__description {
        font-size: 1.5em;
    }

    .home__social-media {
        width: 20vw;
    }

    .home__footer {
        margin-top: 2em;
        font-size: 1.3em;
    }
}

@media (min-width: 1200px) {
    #home {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .home__draw {
        width: 55vw;
    }
    .home__logo {
        width: 15vw;
    }
    .home__content {
        align-items: flex-end;
        width: 40vw;
    }
    .home__social-media {
        width: 12vw;
    }
    .home__social-media-item {
        width: 35px;
    }

    .home__footer {
        margin-top: 0em;
        width: 100%;
        text-align: center;
        height: 2em;
    }
    .home__arrow {
        padding-top: 0em;
        height: 1.2em;
    }
}

@media (min-width: 1500px) {
    @keyframes down {
        0% {
            margin-top: 0em;
        }
        50% {
            margin-top: 0.5em;
        }
        100% {
            margin-top: 0em;
        }
    }
}
