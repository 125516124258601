.view {
    padding-top: 4.5em;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

.subtitle {
    color: var(--title-section-color);
    font-size: 24vw;
    margin-top: 0.5em;
    width: max-content;
    position: absolute;
    z-index: -1;
    font-weight: bolder;
}

.title {
    color: var(--title-color);
    font-size: 2em;
    font-weight: bolder;
}

p {
    font-size: 0.8em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
@media (min-width: 370px) {
    p {
        font-size: 0.93em;
    }
}
@media (min-width: 630px) {
    .title {
        font-size: 4em;
    }
    p {
        font-size: 1.2em;
    }
}
@media (min-width: 1200px) {
    .title {
        font-size: 4em;
    }
}
