.header {
    top: 0;
    display: flex;
    padding: 1em;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color);
    position: fixed;
    z-index: 1;
    box-sizing: border-box;
    height: 5em;
}

.header__logo {
    width: 3.5em;
}

.header__items {
    display: flex;
    width: 14em;
    justify-content: space-between;
}

.header__item {
    font-weight: normal;
    color: var(--header-text-color);
    font-size: 15px;
}

@media (min-width: 630px) {
    .header__logo {
        width: 5em;
    }
    .header__items {
        width: 20em;
    }
    .header__item {
        font-size: 1.1em;
    }
}

@media (min-width: 1200px) {
    .header__logo {
        width: 4.5em;
    }
    .header__items {
        width: 18em;
    }
    .header__item {
        font-size: 1em;
    }
}
