:root {
    --background-color: white;
    --primary-color: #a93653;
    --title-color: #b23957;
    --important-text-color: #383838;
    --text-color: #444444;
    --header-text-color: #535353;
    --title-section-color: rgba(0, 0, 0, 0.05);
    --alternative-primary-color: #a8a8a8;
    --alternative-background-color: rgba(0, 0, 0, 0.8);
    --alternative-text-color: #c0c0c0;
    --alternative-important-text-color: #a9a9a9;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}
a {
    text-decoration: none;
    color: var(--text-color);
}
* {
    scroll-behavior: smooth !important;
}
