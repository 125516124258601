.about__container {
    width: 80%;
}

.about__image {
    height: 45vh;
}
.about__header {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5em;
}
.about__title {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.about__description {
    width: 100%;
}

@media (min-width: 1200px) {
    .about__container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .about__image {
        width: 70%;
        height: auto;
    }
    .about__header {
        width: 50%;
    }
    .about__content {
        width: 40%;
    }
    .about__container {
        width: 90%;
    }
    .about__description {
        width: 90%;
    }
}
