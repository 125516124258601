.work {
    padding-top: 6em;
}
.works {
    width: 100%;
}
.work__container {
    display: flex;
    justify-content: center;
    float: left;
    width: 100vw;
}
.work__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.work__image {
    width: 100%;
    margin-bottom: 0.5em;
    max-width: 100%;
}

.work__title {
    margin-top: 0em;

    margin-bottom: 0.5em;
}
.work__subtitle {
    color: var(--title-color);
    font-weight: 200;
    font-size: 1.5em;
}

.works__container {
    display: flex;
}

@media (min-width: 630px) {
    .work__subtitle {
        font-size: 1.8em;
    }
}
@media (min-width: 1200px) {
    .work__item {
        flex-direction: row;
        width: 90%;
        justify-content: space-around;
    }
    .work__image {
        height: 50vh;
        width: auto;
    }
    .work__details {
        width: 40%;
    }
    .work__description {
        width: 90%;
    }
    .work__subtitle {
        font-size: 2em;
    }
    .work {
        justify-content: center;
    }
    .work__container {
        height: 70vh;
        align-items: flex-start;
    }
}
